import './styles.scss';

import { Collapse, Drawer } from 'antd';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from 'components/Icons';
import { useSearchContext } from 'contexts/search';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getIsLoadingSubmenuProductCategoryList,
  selectSubmenuProductCategories,
} from 'store/selectors/categorySelector';
import {
  selectEquipmentManagerLink,
  selectShouldRenderServiceMenu,
} from 'store/selectors/configSelector';

import SidebarMenu from '../../../SidebarMenu';
import HeaderLogoSection from '../../components';
import ServiceSubmenuMobile from '../../ServicesMenu/Mobile';
import HeaderSubmenuMobile from '../../Submenu/Mobile';

const { Panel } = Collapse;

function HeaderMenuMobile(props) {
  const { isShow, onClose } = props;

  const { t } = useTranslation();
  const { setIsSearchFocused } = useSearchContext();
  const [expandMenuList, setExpandMenuList] = useState(false);

  const isLoadingCategoryList = useSelector(
    getIsLoadingSubmenuProductCategoryList
  );
  const productCategories = useSelector(selectSubmenuProductCategories);
  const equipmentManagerLink = useSelector(selectEquipmentManagerLink);
  const shouldRenderServiceMenu = useSelector(selectShouldRenderServiceMenu);

  const onChangeCollapseExpandIcon = (key) => {
    // key length !== 0 -> collapse is expand
    setExpandMenuList(key.length !== 0);
  };

  const onFocusSearch = useCallback(() => {
    setIsSearchFocused(true);
  }, [setIsSearchFocused]);

  const renderExpandIcon = () =>
    expandMenuList ? <ChevronUpIcon /> : <ChevronDownIcon />;

  return (
    <Drawer
      placement="right"
      visible={isShow}
      onClose={onClose}
      className="header-menu-drawer"
      title={
        <HeaderLogoSection
          inBurgerMenu
          onHideDrawerMobile={onClose}
          onFocusSearch={onFocusSearch}
        />
      }
      width={window.innerWidth}
      closable
      closeIcon={<CloseIcon />}
    >
      {/* products submenu */}
      <HeaderSubmenuMobile
        isLoading={isLoadingCategoryList}
        isShow={isShow}
        onClose={onClose}
        onFocusSearch={onFocusSearch}
        btnName={t('product')}
        productCategories={productCategories}
      />
      {/* services submenu */}
      {shouldRenderServiceMenu && (
        <ServiceSubmenuMobile
          isShow={isShow}
          onClose={onClose}
          onFocusSearch={onFocusSearch}
          btnName={t('services')}
        />
      )}
      {!!equipmentManagerLink && (
        <div className="submenu-mobile">
          <a
            type="button"
            className="submenu-mobile__btn"
            href={equipmentManagerLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('equipmentManagement')}
          </a>
        </div>
      )}
      {/* workspace menu */}
      <Collapse
        className="sidebar__collapse--wrapper"
        onChange={onChangeCollapseExpandIcon}
        expandIconPosition="right"
        expandIcon={() => renderExpandIcon()}
      >
        <Panel
          className="sidebar__header"
          header={t('myBusinessWorkspace')}
          key="1"
        >
          <SidebarMenu />
        </Panel>
      </Collapse>
    </Drawer>
  );
}

HeaderMenuMobile.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
};

HeaderMenuMobile.defaultProps = {
  isShow: false,
  onClose: () => {},
};

export default HeaderMenuMobile;
