import { Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectEquipmentManagerLink,
  selectShouldRenderServiceMenu,
} from 'store/selectors/configSelector';

import usePermission from '../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../libs/constants/modulerights';
import ServiceSubmenu from '../ServicesMenu';
import HeaderSubmenu from '../Submenu';

function HeaderMenu() {
  const { t } = useTranslation();
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showServiceMenu, setShowServiceMenu] = useState(false);

  const toggleSubmenu = (menuState, setMenuState) => {
    window.scrollTo({ top: 0 });
    setMenuState(!menuState);
  };

  const equipmentManagerLink = useSelector(selectEquipmentManagerLink);
  const shouldRenderServiceMenu = useSelector(selectShouldRenderServiceMenu);
  const { hasPermission: hasUseProductNavitationPermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.USE_PRODUCT_NAVIGATION
  );

  return (
    <div className="menu-top-level__wrapper">
      <Menu mode="horizontal" className="header-nav">
        <Menu.Item key="product-navigation">
          <button
            className={
              showProductMenu
                ? 'toggle-submenu-btn active'
                : 'toggle-submenu-btn'
            }
            type="button"
            onClick={() => {
              hasUseProductNavitationPermission &&
                toggleSubmenu(showProductMenu, setShowProductMenu);
            }}
          >
            {t('product')}
          </button>
        </Menu.Item>
        {shouldRenderServiceMenu && (
          <Menu.Item key="service-menu">
            <button
              className={
                showServiceMenu
                  ? 'toggle-submenu-btn active'
                  : 'toggle-submenu-btn'
              }
              type="button"
              onClick={() => toggleSubmenu(showServiceMenu, setShowServiceMenu)}
            >
              {t('services')}
            </button>
          </Menu.Item>
        )}

        {!!equipmentManagerLink && (
          <Menu.Item key="equipment-manager">
            <a
              className="toggle-submenu-btn"
              href={equipmentManagerLink}
              type="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('equipmentManagement')}
            </a>
          </Menu.Item>
        )}
      </Menu>
      {showProductMenu && <HeaderSubmenu setShowSubmenu={setShowProductMenu} />}
      {showServiceMenu && (
        <ServiceSubmenu setShowSubmenu={setShowServiceMenu} />
      )}
    </div>
  );
}

export default HeaderMenu;
